import React, {Fragment} from "react";
import {ButtonIcon, getAccountFio, Icon, Input, TextSearchMarker, Tooltip,} from "@vokymlak/kabinet-ui";
import moment from "moment";
import {NavLink} from "react-router-dom";
import {LOGIC_DUPLICATION_OF_CONTROL, LOGIC_INTERMEDIATE_EXECUTION} from "../../../../../config/constants";
import axios from "axios";
import {ROOT_URL} from "../../../../../config/url";

export const ProjectContractsList = (props) => {
  const {
    list,

    setIsUpdateList,
    isUpdateList,

    searchQuery,

    setModalContractEdit,

    listControlPoints,

    setModalControlPointEdit,

    getContractControlPoint,

    isInfo,
    isAmount,
    isAddition,

    controlGroups,
    isViewControlGroup,

    getPlanDateInPoints,
    getPlanDateInPointsByIntermediateExecution,

    getIndexPage,

    setModalControlPointExecute,

    listPurchaseBasis,
    isControlPoint,

    changeSelectContract,
    isSelectContract,
    selectContracts,

    selectContract,
    setSelectContract,

    notification,

    isClient,

    isCtExecution,

    selectView,
  } = props;

  function truncateString(str) {
    if (str.length <= 260) {
      return str; // возвращает строку без изменений, если количество символов не превышает 160
    } else {
      return str.slice(0, 260) + '...'; // обрезает строку до 160 символов и добавляет троеточие
    }
  }

  const changeDuplicateContractControlPoint = (contractControlPoint) => {
    axios
      .get(
        "/api/contract-control-point/" +
        contractControlPoint.uuid +
        "/duplication-of-control",
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        setIsUpdateList(!isUpdateList);
        notification("Контрольная точка продублирована", "green");
      })
      .catch(() => {
        notification("Контрольную точку нельзя дублировать", "red");
      });
  };

  return (
    !!list && (
      <Fragment>
        {list.map((item, index) => (
          <tr
            className={
              "Table__tr Table__tr_padding Table__tr_border Table__tr_hover " +
              (!!selectContract && selectContract === item.uuid
                ? "Table__tr_focus"
                : "")
            }
            style={{
              backgroundColor: !!item.contract_registration_status
                ? item.contract_registration_status.tag === "REMARK_ISSUED"
                  ? "#ff572250"
                  : item.contract_registration_status.tag === "NOT_REGISTERED"
                    ? "#ffc10733"
                    : !!item.ct_date_closing && (selectView === "current" || selectView === "all")
                      ? "#673ab733"
                      : false
                : false,
            }}

            key={"item_" + item.uuid}
          >
            {isClient && (
              <>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff9c450",
                  }}
                >
                  <NavLink to={"/my/contract/" + item.uuid + '/specifications'} target={"_black"}>
                    <div
                      className="Td__text Td__text_center"
                      style={{height: 20, cursor: "pointer"}}
                    >
                      <Icon
                        name={"custom-circle-arrow-right"}
                        size={20}
                        color={"#202020"}
                      />
                    </div>
                  </NavLink>
                </td>
              </>
            )}

            {!isClient && (
              <>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff9c450",
                  }}
                >
                  <NavLink to={"/contract/" + item.uuid + '/specifications'} target={"_black"}>
                    <div
                      className="Td__text Td__text_center"
                      style={{height: 20, cursor: "pointer"}}
                    >
                      <Icon
                        name={"custom-circle-arrow-right"}
                        size={20}
                        color={"#202020"}
                      />
                    </div>
                  </NavLink>
                </td>
              </>
            )}

            {!isClient && (
              <>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff9c450",
                    width: 24
                  }}
                >
                  <div className="Th__title" style={{height: 40}}>
                    <Input
                      type={"checkbox"}
                      onChange={() => changeSelectContract(item.uuid)}
                      isChecked={isSelectContract(selectContracts, item.uuid)}
                    />
                  </div>
                </td>
              </>
            )}

            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#fff9c450",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip text={"№"} style={{width: "100%", height: "100%"}}>
                <div className="Td__text Td__text_center">
                  {index + 1 + getIndexPage()}
                </div>
              </Tooltip>
            </td>
            {!isClient && (
              <>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff9c450",
                    cursor: "pointer",
                  }}
                  onClick={isClient ? () => null : () => (
                    setModalContractEdit(item), setSelectContract(item.uuid)
                  )}
                >
                  <Tooltip
                    text={"Обновлено"}
                    style={{width: "100%", height: "100%"}}
                  >
                    <div className="Td__text Td__text_center">
                      {!!item.updated_at
                        ? moment(item.updated_at).format("DD.MM.YYYY HH:mm")
                        : moment(item.created_at).format("DD.MM.YYYY HH:mm")}
                      <div style={{
                        fontSize: 11,
                        opacity: .75
                      }}>
                        ({moment(item.created_at).format("DD.MM.YYYY HH:mm")})
                      </div>
                      <div style={{
                        fontSize: 11,
                        opacity: .75
                      }}>
                        {!!item.parsed_at
                          && "выгрузка " + moment(item.parsed_at).format("DD.MM.YYYY HH:mm")}
                      </div>
                    </div>
                  </Tooltip>
                </td>
              </>
            )}

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Номер договора"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.contract_number && (
                      <TextSearchMarker
                        text={item.contract_number}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Реестровый номер"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.registry_number && (
                      <TextSearchMarker
                        text={item.registry_number}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
                <div className="Td__text Td__text_center" style={{
                  fontSize: 10,
                  fontWeight: 400
                }}>
                  {(!!item.ris_id && item.ris_id) ||
                    "-"} / {(!!item.ris_entity_id && item.ris_entity_id) ||
                  "-"}
                </div>
              </Tooltip>
            </td>
            {(isInfo || isClient) && (
              <>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#bbdefb50",
                    cursor: "pointer",
                  }}
                  onClick={isClient ? () => null : () => (
                    setModalContractEdit(item), setSelectContract(item.uuid)
                  )}
                >
                  <Tooltip
                    text={"Номер в ЕИС"}
                    style={{width: "100%", height: "100%"}}
                  >
                    <div className="Td__text Td__text_center">
                      {(!!item.uis_number && (
                          <TextSearchMarker
                            text={item.uis_number}
                            search={searchQuery}
                          />
                        )) ||
                        "-"}
                    </div>
                  </Tooltip>
                </td>

                {/*<td*/}
                {/*  className="Table__td Table__td_border"*/}
                {/*  style={{*/}
                {/*    backgroundColor: "#bbdefb50",*/}
                {/*    cursor: "pointer",*/}
                {/*  }}*/}
                {/*  onClick={isClient ? () => null : () => (*/}
                {/*    setModalContractEdit(item), setSelectContract(item.uuid)*/}
                {/*  )}*/}
                {/*>*/}
                {/*  <Tooltip*/}
                {/*    text={"ЕИС"}*/}
                {/*    style={{width: "100%", height: "100%"}}*/}
                {/*  >*/}
                {/*    <div className="Td__text Td__text_center">*/}
                {/*      {(!!item.is_uis && ("Да")) ||*/}
                {/*        "Нет"}*/}
                {/*    </div>*/}
                {/*  </Tooltip>*/}
                {/*</td>*/}
              </>
            )}
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
                width: 190,
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Основание заключения"}
                style={{width: 190, height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.basis_conclusion && (
                      <TextSearchMarker
                        text={item.basis_conclusion}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Дата заключения"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.date_viewed &&
                      moment(item.date_viewed).format("DD.MM.YYYY")) ||
                    "-"}
                  <div style={{
                    fontSize: 11,
                    opacity: .75
                  }}>
                    {!!item.date_start_action && "начало " + moment(item.date_start_action).format("DD.MM.YYYY")}
                  </div>
                  <div style={{
                    fontSize: 9,
                    opacity: .75
                  }}>
                    {!!item.year_start && "пфхд от " + item.year_start}
                  </div>
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#ffd54f50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Срок действия"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.date_validity &&
                      moment(item.date_validity).format("DD.MM.YYYY")) ||
                    "-"}
                  <div style={{
                    fontSize: 11,
                    opacity: .75
                  }}>
                    {!!item.date_end_action && "конец " + moment(item.date_end_action).format("DD.MM.YYYY")}
                  </div>
                  <div style={{
                    fontSize: 9,
                    opacity: .75
                  }}>
                    {!!item.year_end && "пфхд до " + item.year_end}
                  </div>
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border Table__td_sticky"
              style={{
                cursor: "pointer",
                backgroundColor: "#f1f1f1",
                width: 190,
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip text={"Предмет"} style={{width: 190, height: "100%"}}>
                <div className="Td__text Td__text_center">
                  {(!!item.subject && (
                      <TextSearchMarker
                        text={item.subject}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#bdbdbd50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Начальная цена"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(item.starting_price !== null &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.starting_price / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#bdbdbd50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Цена договора"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(item.amount !== null &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.amount / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#bdbdbd50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Сумма текущего года"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(item.amount_current_year !== null &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.amount_current_year / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#bdbdbd50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Остаток средств текущего года"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(item.current_year_fund_balance !== null &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.current_year_fund_balance / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#bdbdbd50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Сумма по платежам за год"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(item.amount_payments !== null &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.amount_payments / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            {!isClient && (
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bdbdbd50",
                  cursor: "pointer",
                }}
                onClick={isClient ? () => null : () => (
                  setModalContractEdit(item), setSelectContract(item.uuid)
                )}
              >
                <Tooltip
                  text={"Сумма ПФХД за год"}
                  style={{width: "100%", height: "100%"}}
                >
                  <div className="Td__text Td__text_center">
                    {(item.amount_pfhd !== null &&
                        new Intl.NumberFormat("ru-RU").format(
                          (item.amount_pfhd / 100).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </Tooltip>
              </td>
            )}

            {(isAmount || isClient) && (
              <>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#bdbdbd50",
                    cursor: "pointer",
                  }}
                  onClick={isClient ? () => null : () => (
                    setModalContractEdit(item), setSelectContract(item.uuid)
                  )}
                >
                  <Tooltip
                    text={"Сумма экономии"}
                    style={{width: "100%", height: "100%"}}
                  >
                    <div className="Td__text Td__text_center">
                      {(!!item.starting_price &&
                          !!item.amount &&
                          new Intl.NumberFormat("ru-RU").format(
                            ((item.starting_price - item.amount) / 100).toFixed(2)
                          )) ||
                        "-"}
                    </div>
                  </Tooltip>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#ffd54f50",
                    cursor: "pointer",
                  }}
                  onClick={isClient ? () => null : () => (
                    setModalContractEdit(item), setSelectContract(item.uuid)
                  )}
                >
                  <Tooltip
                    text={"Сумма по плана закупки"}
                    style={{width: "100%", height: "100%"}}
                  >
                    <div className="Td__text Td__text_center">
                      {(item.plan_amount !== null &&
                          new Intl.NumberFormat("ru-RU").format(
                            (item.plan_amount / 100).toFixed(2)
                          )) ||
                        "-"}
                    </div>
                  </Tooltip>
                </td>
              </>
            )}

            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#bdbdbd50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Тип действия"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.contract_validity_type &&
                      <TextSearchMarker
                        text={item.contract_validity_type.name}
                        search={searchQuery}
                      />) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            {(isCtExecution || isClient) && (
              <>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#673ab733",
                    cursor: "pointer",
                  }}
                  onClick={isClient ? () => null : () => (
                    setModalContractEdit(item), setSelectContract(item.uuid)
                  )}
                >
                  <Tooltip
                    text={"Источник исполнения"}
                    style={{width: "100%", height: "100%"}}
                  >
                    <div className="Td__text Td__text_center">
                      {(!!item.ct_execution_source && item.ct_execution_source) ||
                        "-"}
                    </div>
                  </Tooltip>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#673ab733",
                    cursor: "pointer",
                  }}
                  onClick={isClient ? () => null : () => (
                    setModalContractEdit(item), setSelectContract(item.uuid)
                  )}
                >
                  <Tooltip
                    text={"Сумма исполнения"}
                    style={{width: "100%", height: "100%"}}
                  >
                    <div className="Td__text Td__text_center">
                      {(item.ct_execution_amount !== null &&
                          new Intl.NumberFormat("ru-RU").format(
                            (item.ct_execution_amount / 100).toFixed(2)
                          )) ||
                        "-"}
                    </div>
                  </Tooltip>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#673ab733",
                    cursor: "pointer",
                  }}
                  onClick={isClient ? () => null : () => (
                    setModalContractEdit(item), setSelectContract(item.uuid)
                  )}
                >
                  <Tooltip
                    text={"Комментарий клиента по исполнению"}
                    style={{width: "100%", height: "100%"}}
                  >
                    <div className="Td__text Td__text_center">
                      {(!!item.ct_execution_note && (
                          <TextSearchMarker
                            text={item.ct_execution_note}
                            search={searchQuery}
                          />
                        )) ||
                        "-"}
                    </div>
                  </Tooltip>
                </td>
              </>
            )}

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Поставщик"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.supplier && (
                      <TextSearchMarker
                        text={item.supplier}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            {/* ЭД Договор зарегистрирован */}
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
                backgroundColor: !!item.contract_registration_status
                  ? item.contract_registration_status.tag === "REMARK_ISSUED"
                    ? "#ff572290"
                    : item.contract_registration_status.tag === "NOT_REGISTERED"
                      ? "#ffc10790"
                      : item.contract_registration_status.tag === "NOT_REQUIRED"
                        ? "#bdbdbd50"
                        : "#bbdefb50"
                  : "#bbdefb50",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <div className="Td__text Td__text_center">
                {(!!item.contract_registration_status && (
                    <TextSearchMarker
                      text={item.contract_registration_status.name.toUpperCase()}
                      search={searchQuery}
                    />
                  )) ||
                  "-"}
              </div>
            </td>

            {/* Ошибка регистрации */}
            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
                backgroundColor: "#bbdefb50",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <div className="Td__text Td__text_center" style={{
                maxWidth: 260,
              }}>
                {(!!item.registration_error && (
                    <TextSearchMarker
                      text={truncateString(item.registration_error)}
                      search={searchQuery}
                    />
                  )) ||
                  "-"}
              </div>
            </td>

            {!isClient && (
              <>
                {/* Ошибка регистрации */}
                <td
                  className="Table__td Table__td_border"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#bbdefb50",
                  }}
                  onClick={isClient ? () => null : () => (
                    setModalContractEdit(item), setSelectContract(item.uuid)
                  )}
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.registration_error_note && (
                        <TextSearchMarker
                          text={item.registration_error_note}
                          search={searchQuery}
                        />
                      )) ||
                      "-"}
                  </div>
                </td>
              </>
            )}

            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#e6ee9c50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Закон-основание"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.purchase_base && (
                      <TextSearchMarker
                        text={item.purchase_base.name}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#e6ee9c50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"КП/ЕП"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {((item.is_kp || item.is_ep) && (
                      <>
                        {item.is_kp && ("КП")}
                        {item.is_ep && ("ЕП")}
                      </>
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#e6ee9c50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"СМП"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(item.is_smp && ("СМП")) || "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#e6ee9c50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"АГ"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(item.is_ag && ("АГ")) || "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#e6ee9c50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"ЭМ"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(item.is_em && ("ЭМ")) || "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#e6ee9c50",
                cursor: "pointer",
                width: 190,
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Способ закупки"}
                style={{width: 190, height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.purchase_way && (
                      <TextSearchMarker
                        text={item.purchase_way.name}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            {isAddition && (
              <>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#bbdefb50",
                    cursor: "pointer",
                  }}
                  onClick={isClient ? () => null : () => (
                    setModalContractEdit(item), setSelectContract(item.uuid)
                  )}
                >
                  <Tooltip
                    text={"Результат правового анализа"}
                    style={{width: "100%", height: "100%"}}
                  >
                    <div className="Td__text Td__text_center">
                      {(!!item.legal_result && (
                          <TextSearchMarker
                            text={item.legal_result}
                            search={searchQuery}
                          />
                        )) ||
                        "-"}
                    </div>
                  </Tooltip>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#c5cae950",
                    cursor: "pointer",
                  }}
                  onClick={isClient ? () => null : () => (
                    setModalContractEdit(item), setSelectContract(item.uuid)
                  )}
                >
                  <Tooltip
                    text={"Примечание"}
                    style={{width: "100%", height: "100%"}}
                  >
                    <div className="Td__text Td__text_center">
                      {(!!item.note && (
                          <TextSearchMarker
                            text={item.note}
                            search={searchQuery}
                          />
                        )) ||
                        "-"}
                    </div>
                  </Tooltip>
                </td>
              </>
            )}
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#bdbdbd50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Заявки по КП"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.count_appeal && item.count_appeal) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#ffd54f50",
                cursor: "pointer",
              }}
              onClick={isClient ? () => null : () => (
                setModalContractEdit(item), setSelectContract(item.uuid)
              )}
            >
              <Tooltip
                text={"Статус"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.contract_status && (
                      <TextSearchMarker
                        text={item.contract_status.name}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: !!getPlanDateInPoints(item)
                  ? moment(getPlanDateInPoints(item).date_control).format(
                    "YYYY-MM-DD"
                  ) === moment().format("YYYY-MM-DD")
                    ? "#ffc107"
                    : moment(
                      moment(getPlanDateInPoints(item).date_control).format(
                        "YYYY-MM-DD"
                      )
                    ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                      ? "#ff572290"
                      : getPlanDateInPoints(item).is_priority
                        ? "#ffc107"
                        : "#9ccc65"
                  : "#bbdefb50",
              }}
              onClick={isClient ? () => null : () => setSelectContract(item.uuid)}
            >
              <Tooltip
                text={
                  (!!getPlanDateInPoints(item) &&
                    getPlanDateInPoints(item).control_point.name) ||
                  "Плановая дата"
                }
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!getPlanDateInPoints(item) && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {!isClient && getPlanDateInPoints(item).is_priority && (
                            <span style={{marginRight: 0}}>
                            <Icon
                              name={"custom-priority"}
                              size={14}
                              color={"#202020"}
                            />
                          </span>
                          )}
                          <span style={{fontSize: 15}}>
                          {moment(
                            getPlanDateInPoints(item).date_control
                          ).format("DD.MM.YYYY")}
                        </span>
                        </div>
                        {!isClient && !getPlanDateInPoints(item).date_execution && (
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <div
                              className={
                                "Action__button Action__button_blue Action__button_mini"
                              }
                              onClick={() =>
                                setModalControlPointExecute(
                                  getPlanDateInPoints(item)
                                )
                              }
                            >
                              Отметить
                            </div>
                          </div>
                        )}
                      </>
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: !!getPlanDateInPointsByIntermediateExecution(item)
                  ? moment(getPlanDateInPointsByIntermediateExecution(item).date_control).format(
                    "YYYY-MM-DD"
                  ) === moment().format("YYYY-MM-DD")
                    ? "#ffc107"
                    : moment(
                      moment(getPlanDateInPointsByIntermediateExecution(item).date_control).format(
                        "YYYY-MM-DD"
                      )
                    ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                      ? "#ff572290"
                      : getPlanDateInPointsByIntermediateExecution(item).is_priority
                        ? "#ffc107"
                        : "#9ccc65"
                  : "#bbdefb50",
              }}
              onClick={isClient ? () => null : () => setSelectContract(item.uuid)}
            >
              <Tooltip
                text={
                  (!!getPlanDateInPointsByIntermediateExecution(item) &&
                    getPlanDateInPointsByIntermediateExecution(item).control_point.name) ||
                  "Плановая дата исполнения"
                }
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!getPlanDateInPointsByIntermediateExecution(item) && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {!isClient && getPlanDateInPointsByIntermediateExecution(item).is_priority && (
                            <span style={{marginRight: 0}}>
                            <Icon
                              name={"custom-priority"}
                              size={14}
                              color={"#202020"}
                            />
                          </span>
                          )}
                          <span style={{fontSize: 15}}>
                          {moment(
                            getPlanDateInPointsByIntermediateExecution(item).date_control
                          ).format("DD.MM.YYYY")}
                        </span>
                        </div>
                        {!isClient && !getPlanDateInPointsByIntermediateExecution(item).date_execution && (
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <div
                              className={
                                "Action__button Action__button_blue Action__button_mini"
                              }
                              onClick={() =>
                                setModalControlPointExecute(
                                  getPlanDateInPointsByIntermediateExecution(item)
                                )
                              }
                            >
                              Отметить
                            </div>
                          </div>
                        )}
                      </>
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            {!isClient && (
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#ffd54f50",
                  cursor: "pointer",
                }}
                onClick={() => (
                  setModalContractEdit(item), setSelectContract(item.uuid)
                )}
              >
                <Tooltip
                  text={"Комментарий к исполнению"}
                  style={{width: "100%", height: "100%"}}
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.payment_execution && (
                        <TextSearchMarker
                          text={item.payment_execution}
                          search={searchQuery}
                        />
                      )) ||
                      "-"}
                  </div>
                </Tooltip>
              </td>
            )}

            {/* Точки контроля */}
            {!!listControlPoints &&
              listControlPoints.map(
                (controlPoint) =>
                  (isViewControlGroup(controlGroups, controlPoint) || isClient) && (
                    <td
                      className={
                        "Table__td Table__td_border " +
                        (!isControlPoint(listPurchaseBasis, item, controlPoint)
                          ? "Table__td_tiger"
                          : "")
                      }
                      key={"td_control_point_" + controlPoint.uuid}
                      style={{
                        backgroundColor: "#bbdefb50",
                        position: "relative",
                        padding: 4
                      }}
                      onContextMenu={isClient ? () => null :
                        isControlPoint(listPurchaseBasis, item, controlPoint)
                          ? (event) => (
                            event.preventDefault(),
                              setModalControlPointEdit({
                                item,
                                controlPoint,
                              }),
                              setSelectContract(item.uuid)
                          )
                          : null
                      }
                    >
                      {!isClient && !!controlPoint.control_point_logics &&
                        controlPoint.control_point_logics.map(
                          (controlPointLogic) => (
                            <Fragment key={item.uuid + ":" + controlPointLogic.uuid}>
                              {item.is_intermediate_execution && !!item.date_viewed && controlPointLogic.logic.tag === LOGIC_INTERMEDIATE_EXECUTION && (
                                <Tooltip text={controlPointLogic.logic.name}
                                         style={{position: "absolute", right: -1, top: -2}}>
                                  <Icon name={"custom-change-circle"} size={14}
                                        color={"#0064ff"}/>
                                </Tooltip>
                              )}
                              {controlPointLogic.logic.tag === LOGIC_DUPLICATION_OF_CONTROL && !!getContractControlPoint(item, controlPoint) && (
                                <ButtonIcon
                                  tooltipText={controlPointLogic.logic.name}
                                  iconName={"custom-circle-arrow-right"}
                                  iconColor={"#0064ff"}
                                  size={18}
                                  style={{
                                    position: "absolute",
                                    right: -9,
                                    top: 1,
                                    zIndex: 1
                                  }}
                                  onClick={() => changeDuplicateContractControlPoint(getContractControlPoint(item, controlPoint))}
                                />
                              )}
                            </Fragment>
                          )
                        )}

                      <Tooltip
                        text={
                          isControlPoint(
                            listPurchaseBasis,
                            item,
                            controlPoint
                          ) && controlPoint.name
                        }
                        style={{width: "100%", height: "100%"}}
                      >
                        <div className="Td__text Td__text_center">
                          {!isClient && !!getContractControlPoint(item, controlPoint) && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                width: "100%",
                                textDecoration: !!getContractControlPoint(item, controlPoint)
                                  .deleted_at && "line-through",
                                opacity: !!getContractControlPoint(item, controlPoint)
                                  .deleted_at && 0.5
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  marginBottom: 0,
                                }}
                              >
                                {!getContractControlPoint(item, controlPoint)
                                  .deleted_at && getContractControlPoint(item, controlPoint)
                                  .is_priority && (
                                  <span style={{marginRight: 0}}>
                                    <Icon
                                      name={"custom-priority"}
                                      size={14}
                                      color={"#202020"}
                                    />
                                  </span>
                                )}
                                <span style={{fontSize: 15}}>
                                  {moment(
                                    getContractControlPoint(item, controlPoint)
                                      .date_control
                                  ).format("DD.MM.YYYY")}
                                </span>
                              </div>

                              {!!getContractControlPoint(item, controlPoint)
                                .date_execution && (
                                <div
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    className={
                                      "Action__button Action__button_gray Action__button_mini"
                                    }
                                    onClick={() =>
                                      setModalControlPointExecute(
                                        getContractControlPoint(
                                          item,
                                          controlPoint
                                        )
                                      )
                                    }
                                  >
                                    {moment(
                                      getContractControlPoint(
                                        item,
                                        controlPoint
                                      ).date_execution
                                    ).format("DD.MM.YYYY")}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: 12,
                                      fontWeight: 400,
                                      width: "100%",
                                    }}
                                  >
                                    {(!!getContractControlPoint(
                                          item,
                                          controlPoint
                                        ).executor &&
                                        getAccountFio(
                                          getContractControlPoint(
                                            item,
                                            controlPoint
                                          ).executor
                                        )) ||
                                      "-"}
                                  </div>
                                </div>
                              )}
                              {!getContractControlPoint(item, controlPoint)
                                .date_execution && !getContractControlPoint(item, controlPoint)
                                .deleted_at && (
                                <div
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    className={
                                      "Action__button Action__button_blue Action__button_mini"
                                    }
                                    onClick={() =>
                                      setModalControlPointExecute(
                                        getContractControlPoint(
                                          item,
                                          controlPoint
                                        )
                                      )
                                    }
                                  >
                                    Отметить
                                  </div>
                                </div>
                              )}
                            </div>
                          )}

                          {isClient && !!getContractControlPoint(item, controlPoint) && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                width: "100%",
                              }}
                            >
                              {!getContractControlPoint(item, controlPoint)
                                .date_execution && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    marginBottom: 0,
                                  }}
                                >
                                <span style={{fontSize: 15}}>
                                  {moment(
                                    getContractControlPoint(item, controlPoint)
                                      .date_control
                                  ).format("DD.MM.YYYY")}
                                </span>
                                </div>
                              )}

                              {!!getContractControlPoint(item, controlPoint)
                                .date_execution && (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                    marginBottom: 0,
                                  }}
                                >
                                <span style={{fontSize: 15}}>
                                    {moment(
                                      getContractControlPoint(
                                        item,
                                        controlPoint
                                      ).date_execution
                                    ).format("DD.MM.YYYY")}
                                     </span>
                                </div>
                              )}
                            </div>
                          )}

                          {!getContractControlPoint(item, controlPoint) &&
                            isControlPoint(
                              listPurchaseBasis,
                              item,
                              controlPoint
                            ) &&
                            "-"}
                        </div>
                      </Tooltip>
                    </td>
                  )
              )}

            {/*<td*/}
            {/*  className="Table__td Table__td_border"*/}
            {/*  style={{*/}
            {/*    backgroundColor: "#bdbdbd50",*/}
            {/*    cursor: "pointer",*/}
            {/*  }}*/}
            {/*  onClick={isClient ? () => null : () => (*/}
            {/*    setModalContractEdit(item), setSelectContract(item.uuid)*/}
            {/*  )}*/}
            {/*>*/}
            {/*  <Tooltip*/}
            {/*    text={"Сумма по платежам"}*/}
            {/*    style={{width: "100%", height: "100%"}}*/}
            {/*  >*/}
            {/*    <div className="Td__text Td__text_center">*/}
            {/*      {(item.amount_payments !== null &&*/}
            {/*          new Intl.NumberFormat("ru-RU").format(*/}
            {/*            (item.amount_payments / 100).toFixed(2)*/}
            {/*          )) ||*/}
            {/*        "-"}*/}
            {/*    </div>*/}
            {/*  </Tooltip>*/}
            {/*</td>*/}
            {/*{!isClient && (*/}
            {/*  <td*/}
            {/*    className="Table__td Table__td_border"*/}
            {/*    style={{*/}
            {/*      backgroundColor: "#ffd54f50",*/}
            {/*      cursor: "pointer",*/}
            {/*    }}*/}
            {/*    onClick={() => (*/}
            {/*      setModalContractEdit(item), setSelectContract(item.uuid)*/}
            {/*    )}*/}
            {/*  >*/}
            {/*    <Tooltip*/}
            {/*      text={"Комментарий к исполнению"}*/}
            {/*      style={{width: "100%", height: "100%"}}*/}
            {/*    >*/}
            {/*      <div className="Td__text Td__text_center">*/}
            {/*        {(!!item.payment_execution && (*/}
            {/*            <TextSearchMarker*/}
            {/*              text={item.payment_execution}*/}
            {/*              search={searchQuery}*/}
            {/*            />*/}
            {/*          )) ||*/}
            {/*          "-"}*/}
            {/*      </div>*/}
            {/*    </Tooltip>*/}
            {/*  </td>*/}
            {/*)}*/}
            {/*<td*/}
            {/*  className="Table__td Table__td_border"*/}
            {/*  style={{*/}
            {/*    backgroundColor: "#bdbdbd50",*/}
            {/*    cursor: "pointer",*/}
            {/*  }}*/}
            {/*  onClick={isClient ? () => null : () => (*/}
            {/*    setModalContractEdit(item), setSelectContract(item.uuid)*/}
            {/*  )}*/}
            {/*>*/}
            {/*  <Tooltip*/}
            {/*    text={"Остаток оплаты по контракту"}*/}
            {/*    style={{width: "100%", height: "100%"}}*/}
            {/*  >*/}
            {/*    <div className="Td__text Td__text_center">*/}
            {/*      {(!!item.amount_payments &&*/}
            {/*          !!item.amount &&*/}
            {/*          new Intl.NumberFormat("ru-RU").format(((item.amount - item.amount_payments) / 100).toFixed(2))) ||*/}
            {/*        "-"}*/}
            {/*    </div>*/}
            {/*  </Tooltip>*/}
            {/*</td>*/}
          </tr>
        ))}
      </Fragment>
    )
  );
};

import React, {useState} from "react";
import {Icon, Tooltip} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../../styles/modules/Block.module.scss";
import ModalProjectPurchaseWayEdit from "./ModalProjectPurchaseWayEdit";

export const CalcEpTable = (props) => {
  const {
    object,
    calculations,
    setIsUpdateCalculations,
    isUpdateCalculations,
    isClient,
  } = props;

  const [modalEdit, setModalEdit] = useState(null);

  return (
    !!calculations && (
      <div className={styleContent._block + " " + styleContent._block_bottom}>
        <div className={styleContent._block__title}>Основания заключения с ЕП</div>
        <div className={styleContent._block__content}>
          <table className="Table">
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  Статьи
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dbdbdb",
                }}
              >
                <div className="Th__title">
                  План
                  {!isClient && (
                  <Tooltip
                    text={
                      'Редактируемое&nbsp;поле для&nbsp;плановых&nbsp;показателей'
                    }
                    style={{marginLeft: 4, height: 20}}
                  >
                    <Icon name={"custom-edit"} color={"#202020"} size={20}/>
                  </Tooltip>
                  )}
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  Факт
                  <Tooltip
                    text={'Суммируется&nbsp;по&nbsp;"Сумма&nbsp;текущего&nbsp;года", ' +
                      'при&nbsp;отсутствии&nbsp;используется&nbsp;"Сумма&nbsp;ПФХД&nbsp;за&nbsp;год"&nbsp;, ' +
                      'при&nbsp;отсутствии&nbsp;используется&nbsp;"Начальная&nbsp;цена"&nbsp;, ' +
                      'или&nbsp;если&nbsp;договор&nbsp;расторгнут&nbsp;используется "Сумма&nbsp;по&nbsp;платежам"&nbsp;'}
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            {(!!calculations.ep_ways && calculations.ep_ways.map((epWay, index) => (
              <tr
                key={index}
                className={
                  "Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
                }
              >
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff59d50",
                    width: '50%'
                  }}
                >
                  <div className="Td__text Td__text_left">
                    {(!!epWay.title && epWay.title) || "-"}
                    {(!!epWay.title && epWay.title === "25. Закупка по факту признания несостоявшимися открытого, двухэтапного, повторного конкурсов, конкурса с ограниченным участием, электронного аукциона, запроса котировок или предложений" &&
                      <span style={{
                        marginLeft: 4,
                        opacity: .75
                      }}>(без электронного магазина)</span>)}
                  </div>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#dbdbdb50",
                    cursor: "pointer"
                  }}
                  onClick={!isClient ? () => setModalEdit({
                    project: object,
                    purchaseWay: epWay.purchase_way,
                    amount: epWay.plan_amount
                  }) : () => null}
                >
                  <div className="Td__text Td__text_center">
                    {(!!epWay.plan_amount &&
                        new Intl.NumberFormat("ru-RU").format(
                          Number(epWay.plan_amount / 100).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff59d50",
                  }}
                >
                  <div className="Td__text Td__text_center">
                    {(!!epWay.amount &&
                        new Intl.NumberFormat("ru-RU").format(
                          Number(epWay.amount / 100).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </td>
              </tr>
            )))}
            <tr
              className={
                "Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
              }
            >
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                  width: '50%'
                }}
              >
                <div className="Td__text Td__text_right" style={{
                  textAlign: "right"
                }}>
                  ИТОГО:
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#dbdbdb50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.ep_ways &&
                    new Intl.NumberFormat("ru-RU").format(
                      (calculations.ep_ways.reduce((acc, way) => acc + way.plan_amount, 0) / 100).toFixed(2)
                    )) || "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.ep_ways &&
                    new Intl.NumberFormat("ru-RU").format(
                      (calculations.ep_ways.reduce((acc, way) => acc + way.amount, 0) / 100).toFixed(2)
                    )) || "-"}
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div style={{
            marginTop: 8,
            fontSize: 14,
          }}>
            <p>
              <b>Факт</b> - суммируется по "Сумма&nbsp;текущего&nbsp;года", при отсутствии используется
              "Сумма&nbsp;ПФХД&nbsp;за&nbsp;год", при отсутствии используется "Начальная&nbsp;цена", или если
              договор расторгнут используется "Сумма&nbsp;по&nbsp;платежам"
            </p>
          </div>
        </div>

        {!!modalEdit && (
          <ModalProjectPurchaseWayEdit
            project={modalEdit.project}
            purchaseWay={modalEdit.purchaseWay}
            amount={modalEdit.amount}
            successfulRequest={() => (
              setIsUpdateCalculations(!isUpdateCalculations)
            )}
            handleClose={() => setModalEdit(null)}
          />
        )}
      </div>
    )
  );
};

import React, {useState} from "react";
import {Icon, Tooltip} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../../styles/modules/Block.module.scss";
import ModalProjectPurchaseWayEdit from "./ModalProjectPurchaseWayEdit";

export const CalcP02Table = (props) => {
  const {
    object,
    calculations,
    setIsUpdateCalculations,
    isUpdateCalculations,
    isClient,
  } = props;

  const [modalEdit, setModalEdit] = useState(null);

  function getP02(calculations) {
    return (
      !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.1) > 50_000_000_00
        ? 50_000_000_00
        : !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.1) < 50_000_000_00
          ? Number((calculations.sgoz_amount) * 0.1)
          : 0
    )
  }

  return (
    !!calculations && (
      <div className={styleContent._block + " " + styleContent._block_bottom}>
        <div className={styleContent._block__title}>02. Закупки на сумму, не превышающую установленный размер (600
          тыс.руб.)
        </div>
        <div className={styleContent._block__content}>
          <table className="Table">
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  СГОЗ
                  <Tooltip
                    text={"Сумма&nbsp;СГОЗ"}
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  02. - 223 (10%)
                  <Tooltip
                    text={
                      "10%&nbsp;от&nbsp;СГОЗ,&nbsp;до&nbsp;50&nbsp;000&nbsp;000"
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  02. - 223 (сумма)
                  <Tooltip
                    text={'Суммируется&nbsp;по&nbsp;"Сумма&nbsp;текущего&nbsp;года", ' +
                      'при&nbsp;отсутствии&nbsp;используется&nbsp;"Сумма&nbsp;ПФХД&nbsp;за&nbsp;год"&nbsp;(02.), ' +
                      'при&nbsp;отсутствии&nbsp;используется&nbsp;"Начальная&nbsp;цена"&nbsp;(02.), ' +
                      'или&nbsp;если&nbsp;договор&nbsp;расторгнут&nbsp;используется "Сумма&nbsp;по&nbsp;платежам"&nbsp;(02.)'}
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dbdbdb",
                }}
              >
                <div className="Th__title">
                  02. - 223 (Переходящие)
                  {!isClient && (
                  <Tooltip
                    text={
                      'Редактируемое&nbsp;поле для&nbsp;лимитов&nbsp;договоров, которые&nbsp;еще не&nbsp;перерегистрировали'
                    }
                    style={{marginLeft: 4, height: 20}}
                  >
                    <Icon name={"custom-edit"} color={"#202020"} size={20}/>
                  </Tooltip>
                    )}
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  02. - 223 (разница)
                  <Tooltip
                    text={
                      "Разница&nbsp;между&nbsp;10%&nbsp;от&nbsp;СГОЗ и&nbsp;суммой&nbsp;текущего&nbsp;года&nbsp;(02.)"
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              className={
                "Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
              }
            >
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.sgoz_amount &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(calculations.sgoz_amount / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!getP02(calculations) &&
                      new Intl.NumberFormat("ru-RU").format((getP02(calculations) / 100).toFixed(2)))
                    || "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.p02_starting_price &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(calculations.p02_starting_price / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#dbdbdb50",
                  cursor: "pointer"
                }}
                onClick={!isClient ? () => setModalEdit({
                  project: object,
                  purchaseWay: calculations.p02_purchase_way,
                  amount: calculations.p02_transition_price
                }) : () => null}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.p02_transition_price &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(calculations.p02_transition_price / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!getP02(calculations) &&
                    new Intl.NumberFormat("ru-RU").format(
                      ((
                        getP02(calculations)
                        - calculations.p02_starting_price
                        - calculations.p02_transition_price
                      ) / 100).toFixed(2)
                    )) || "-"}
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div style={{
            marginTop: 8,
            fontSize: 14,
          }}>
            <p>
              <b>СГОЗ</b> - сумма СГОЗ
            </p>
            <p>
              <b>02. - 223 (10%)</b> - 10% от СГОЗ, до 50&nbsp;000&nbsp;000
            </p>
            <p>
              <b>02. - 223 (сумма)</b> - суммируется по "Сумма&nbsp;текущего&nbsp;года", при отсутствии используется
              "Сумма&nbsp;ПФХД&nbsp;за&nbsp;год" (02.), при отсутствии используется "Начальная&nbsp;цена" (02.), или если
              договор расторгнут используется "Сумма&nbsp;по&nbsp;платежам" (02.)
            </p>
            <p>
              <b>02. - 223 (разница)</b> - разница между 10% от СГОЗ и суммой текущего года (02.)
            </p>
          </div>
        </div>

        {!!modalEdit && (
          <ModalProjectPurchaseWayEdit
            project={modalEdit.project}
            purchaseWay={modalEdit.purchaseWay}
            amount={modalEdit.amount}
            successfulRequest={() => (
              setIsUpdateCalculations(!isUpdateCalculations)
            )}
            handleClose={() => setModalEdit(null)}
          />
        )}
      </div>
    )
  );
};

import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import {ButtonIcon, getAccountFio, Icon, Tooltip} from "@vokymlak/kabinet-ui";
import ModalControlPointExecute from "../../ScreenObjects/Project/components/ProjectContracts/ModalControlPointExecute";
import ModalControlPointEdit from "../../ScreenObjects/Project/components/ProjectContracts/ModalControlPointEdit";

export const ControlPointTasksList = (props) => {
  const [modalControlPointExecute, setModalControlPointExecute] =
    useState(null);
  const [modalControlPointEdit, setModalControlPointEdit] = useState(null);

  const getContractControlPoint = (item, controlPoint) => {
    let getContractControlPoint = null;

    if (!!item && !!item.control_points) {
      item.control_points.map((point) => {
        if (point.control_point.uuid === controlPoint.uuid) {
          getContractControlPoint = point;
        }
      });
    }

    return getContractControlPoint;
  };

  return (
    !!props.list && (
      <Fragment>
        {props.list.map((item) => (
          <tr
            className="Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
            key={"item_" + item.uuid}
            style={{
              backgroundColor: (!!item.contract &&!!item.contract.contract_registration_status &&
                  item.contract.contract_registration_status.tag === 'REMARK_ISSUED') ? "#ff572290" :
                (props.isRed
                ? "#ef9a9a"
                : !!item.date_execution
                  ? "#a5d6a7"
                  : moment(item.date_control).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                    ? item.is_priority
                      ? "#ffe082"
                      : "inherit"
                    : !!moment(
                      moment(item.date_control).format("YYYY-MM-DD")
                    ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                      ? "#ef9a9a"
                      : item.is_priority
                        ? "#ffe082"
                        : "inherit"),
            }}
          >
            <td className="Table__td Table__td_border">
              <div
                className="Td__text Td__text_center"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item.is_priority && (
                  <Icon name={"custom-priority"} size={16} color={"#202020"}/>
                )}
                {!item.is_priority && "-"}
              </div>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                maxWidth: 140,
              }}
            >
              <div
                className="Td__text Td__text_center"
                style={{
                  maxWidth: 140,
                }}
              >
                {(!!item.contract &&
                    !!item.contract.project &&
                    !!item.contract.project.company &&
                    item.contract.project.company.short_name) ||
                  "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.date_control
                  ? moment(item.date_control).format("DD.MM.YYYY")
                  : null}
              </div>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                maxWidth: 140,
              }}
            >
              <div
                className="Td__text Td__text_center"
                style={{
                  maxWidth: 140,
                }}
              >
                {(!!item.contract &&
                    !!item.contract.subject &&
                    item.contract.subject) ||
                  "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.contract &&
                    !!item.contract.purchase_base &&
                    item.contract.purchase_base.name) ||
                  "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.contract &&
                    !!item.contract.purchase_way &&
                    item.contract.purchase_way.name) ||
                  "-"}
              </div>
            </td>

            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.contract &&
                    !!item.contract.registry_number &&
                    item.contract.registry_number) ||
                  "-"}
              </div>
            </td>

            <td className="Table__td Table__td_border"
                style={{
                  minWidth: 100,
                }}>
              <div className="Td__text Td__text_center Td__text_break">
                {(!!item.contract &&
                    !!item.contract.uis_number &&
                    item.contract.uis_number) ||
                  "-"}
              </div>
            </td>

            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.contract &&
                    !!item.contract.amount &&
                    item.contract.amount / 100) ||
                  "-"}
              </div>
            </td>

            <td className="Table__td Table__td_border">
              {!!item.contract && !!item.contract.project && (
                <NavLink
                  to={
                    "/project/" +
                    item.contract.project.uuid +
                    "/contracts?search=" +
                    item.contract.uuid
                  }
                >
                  <div
                    className="Td__text Td__text_center"
                    style={{
                      cursor: "pointer",
                      color: "#0064ff",
                      textDecoration: "underline",
                    }}
                  >
                    {!!item.control_point && item.control_point.name}
                  </div>
                </NavLink>
              )}
              {!item.contract ||
                (!!item.contract && !item.contract.project && (
                  <div
                    className="Td__text Td__text_center"
                    style={{
                      cursor: "pointer",
                      color: "#0064ff",
                      textDecoration: "underline",
                    }}
                  >
                    {!!item.control_point && item.control_point.name}
                  </div>
                ))}
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.contract &&!!item.contract.contract_registration_status &&
                    item.contract.contract_registration_status.name.toUpperCase()) ||
                  "-"}
              </div>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                maxWidth: 140,
              }}
            >
              <div
                className="Td__text Td__text_center"
                style={{
                  maxWidth: 140,
                }}
              >
                {(!!item.contract &&
                    !!item.contract.note &&
                    item.contract.note) ||
                  "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.contract &&
                    !!item.contract.project &&
                    !!item.contract.project &&
                    !!item.contract.project.employees &&
                  item.contract.project.employees.filter(f => f.project_employee_role_type.tag === 'CURATOR').map(e => (
                    <span key={item.uuid + "_" + e.uuid}>
                      {getAccountFio(e.employee)}
                    </span>
                  ))
                  ) ||
                  "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.contract &&
                    !!item.contract.project &&
                    !!item.contract.project &&
                    !!item.contract.project.employees &&
                    item.contract.project.employees.filter(f => f.project_employee_role_type.tag === 'EXECUTOR').map(e => (
                      <span key={item.uuid + "_" + e.uuid}>
                      {getAccountFio(e.employee)}
                    </span>
                    ))
                  ) ||
                  "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.who && getAccountFio(item.who)) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.whom && getAccountFio(item.whom)) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border"
                onContextMenu={(event) => (
                  event.preventDefault(),
                    setModalControlPointEdit({
                      item: item.contract,
                      controlPoint: item.control_point,
                    })
                )
                }>
              {/*<div className="Td__text Td__text_center">*/}
              {/*  {!!item.date_execution && (*/}
              {/*    <>*/}
              {/*      <div*/}
              {/*        className={*/}
              {/*          "Action__button Action__button_gray Action__button_mini"*/}
              {/*        }*/}
              {/*        onClick={() => setModalControlPointExecute(item)}*/}
              {/*      >*/}
              {/*        {moment(item.date_execution).format("DD.MM.YYYY")}*/}
              {/*      </div>*/}
              {/*      <div*/}
              {/*        style={{*/}
              {/*          fontSize: 12,*/}
              {/*          fontWeight: 400,*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        {!!item.executor && getAccountFio(item.executor)}*/}
              {/*      </div>*/}
              {/*    </>*/}
              {/*  )}*/}
              {/*  {!item.date_execution && (*/}
              {/*    <div*/}
              {/*      className={*/}
              {/*        "Action__button Action__button_blue Action__button_maxi"*/}
              {/*      }*/}
              {/*      onClick={() => setModalControlPointExecute(item)}*/}
              {/*    >*/}
              {/*      Отметить*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*</div>*/}


                <div className="Td__text Td__text_center">

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "100%",
                        textDecoration: !!item.deleted_at && "line-through",
                        opacity: !!item.deleted_at && 0.5
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          marginBottom: 0,
                        }}
                      >
                        {!item.deleted_at && item.is_priority && (
                          <span style={{marginRight: 0}}>
                                    <Icon
                                      name={"custom-priority"}
                                      size={14}
                                      color={"#202020"}
                                    />
                                  </span>
                        )}
                        <span style={{fontSize: 15}}>
                                  {moment(
                                    item.date_control
                                  ).format("DD.MM.YYYY")}
                                </span>
                      </div>

                      {!!item.date_execution && (
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <div
                            className={
                              "Action__button Action__button_gray Action__button_mini"
                            }
                            onClick={() =>
                              setModalControlPointExecute(
                                item
                              )
                            }
                          >
                            {moment(
                              item.date_execution
                            ).format("DD.MM.YYYY")}
                          </div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 400,
                              width: "100%",
                            }}
                          >
                            {(!!item.executor &&
                                getAccountFio(
                                  item.executor
                                )) ||
                              "-"}
                          </div>
                        </div>
                      )}
                      {!item.date_execution && !item.deleted_at && (
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <div
                            className={
                              "Action__button Action__button_blue Action__button_maxi"
                            }
                            onClick={() =>
                              setModalControlPointExecute(
                                item
                              )
                            }
                          >
                            Отметить
                          </div>
                        </div>
                      )}
                    </div>
                </div>
            </td>
          </tr>
        ))}


        {!!modalControlPointEdit && (
          <ModalControlPointEdit
            contract={modalControlPointEdit.item}
            controlPoint={modalControlPointEdit.controlPoint}
            successfulRequest={props.successfulRequest}
            handleClose={() => setModalControlPointEdit(null)}
          />
        )}
        {!!modalControlPointExecute && (
          <ModalControlPointExecute
            contractControlPoint={modalControlPointExecute}
            successfulRequest={props.successfulRequest}
            handleClose={() => setModalControlPointExecute(null)}
          />
        )}
      </Fragment>
    )
  );
};

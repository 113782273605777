import React from "react";
import {Icon, Tooltip} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../../styles/modules/Block.module.scss";

export const CalcProcurementTable = (props) => {
  const {
    object,
    calculations,
    isClient,
  } = props;

  function getBalanceOfUnContractedFunds(object, calculations) {
    let balance = calculations.sgoz_amount -
      calculations.amount_current_year_e_shop -
      calculations.compete_procurement_amount_current_year

    if (object.law.tag === "44") {
      if (!!calculations.p4_starting_price) {
        balance -= calculations.p4_starting_price
      }
      if (!!calculations.p4_transition_price) {
        balance -= calculations.p4_transition_price
      }
      if (!!calculations.p5_starting_price) {
        balance -= calculations.p5_starting_price
      }
      if (!!calculations.p5_transition_price) {
        balance -= calculations.p5_transition_price
      }
    }

    if (object.law.tag === "223") {
      if (!!calculations.p02_starting_price) {
        balance -= calculations.p02_starting_price
      }
      if (!!calculations.p02_transition_price) {
        balance -= calculations.p02_transition_price
      }
      if (!!calculations.p03_starting_price) {
        balance -= calculations.p03_starting_price
      }
      if (!!calculations.p03_transition_price) {
        balance -= calculations.p03_transition_price
      }
    }

    if (object.law.tag === "44") {
      if (!!calculations.ep_ways) {
        calculations.ep_ways.forEach(epWay => {
          // if (!!epWay.amount && !epWay.title.includes('25. Закупка по факту признания несостоявшимися')) {
          //   balance -= epWay.amount
          // }
          if (!!epWay.amount) {
            balance -= epWay.amount
          }
        });
      }
    }

    if (object.law.tag === "223") {
      if (!!calculations.ep_ways) {
        calculations.ep_ways.forEach(epWay => {
          if (!!epWay.amount) {
            balance -= epWay.amount
          }
        });
      }
    }

    return balance;
  }

  return (
    !!calculations && (
      <div className={styleContent._block + " " + styleContent._block_bottom}>
        <div className={styleContent._block__title}>Расчет закупок</div>
        <div className={styleContent._block__content}>
          <table className="Table">
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#c5e1a5",
                }}
              >
                <div className="Th__title">
                  Цена договора
                  <Tooltip
                    text={'Суммируется&nbsp;по&nbsp;"Цена&nbsp;договора"'}
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#c5e1a5",
                }}
                colSpan={2}
              >
                <div className="Th__title">
                  Сумма начатых процедур
                  <Tooltip
                    text={'Суммируется&nbsp;по&nbsp;"Сумма&nbsp;текущего&nbsp;года", ' +
                      'при&nbsp;отсутствии&nbsp;используется&nbsp;"Сумма&nbsp;ПФХД&nbsp;за&nbsp;год"&nbsp;, ' +
                      'при&nbsp;отсутствии&nbsp;используется&nbsp;"Начальная&nbsp;цена"&nbsp;, ' +
                      'или&nbsp;если&nbsp;договор&nbsp;расторгнут&nbsp;используется "Сумма&nbsp;по&nbsp;платежам"&nbsp;'}
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#c5e1a5",
                }}
                colSpan={2}
              >
                <div className="Th__title">
                  Объем контрактации (% от СГОЗ)
                  <Tooltip
                    text={
                      'Соотношение&nbsp;"Сумма&nbsp;начатых&nbsp;процедур"&nbsp;к&nbsp;"СГОЗ"'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#c5e1a5",
                }}
              >
                <div className="Th__title">
                  Сумма экономии
                  <Tooltip
                    text={
                      'Суммируется&nbsp;разница&nbsp;между "Цена&nbsp;договора"&nbsp;и&nbsp;"Начальная&nbsp;цена"'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#c5e1a5",
                }}
              >
                <div className="Th__title">
                  Остаток незаконтрактованных средств
                  <Tooltip
                    text={
                      'Свободные&nbsp;лимиты'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              className={
                "Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
              }
            >
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#c5e1a550",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.amount_procurement &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(calculations.amount_procurement / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#c5e1a550",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.starting_price_procurement &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          calculations.starting_price_procurement / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#c5e1a550",
                }}
              >
                <div className="Td__text Td__text_center">
                  {!!calculations.dash_starting_price_procurement
                    ? "_" +
                    new Intl.NumberFormat("ru-RU").format(
                      Number(
                        calculations.dash_starting_price_procurement / 100
                      ).toFixed(2)
                    )
                    : "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#c5e1a550",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.sgoz_amount &&
                      (!!calculations.starting_price_procurement
                        ? (
                        calculations.starting_price_procurement /
                        (calculations.sgoz_amount / 100)
                      ).toFixed(2) + "%"
                        : "-")) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#c5e1a550",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.sgoz_amount &&
                      (!!calculations.dash_starting_price_procurement
                        ? "_" +
                        (
                          calculations.dash_starting_price_procurement /
                          (calculations.sgoz_amount / 100)
                        ).toFixed(2) +
                        "%"
                        : "-")) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#c5e1a550",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.amount_savings_procurement &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          calculations.amount_savings_procurement / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#c5e1a550",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!getBalanceOfUnContractedFunds(object, calculations) &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          getBalanceOfUnContractedFunds(object, calculations) / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div style={{
            marginTop: 8,
            fontSize: 14,
          }}>
            <p>
              <b>Цена договора</b> - суммируется по "Цена&nbsp;договора"
            </p>
            <p>
              <b>Сумма начатых процедур</b> - суммируется по "Сумма&nbsp;текущего&nbsp;года", при отсутствии используется
              "Сумма&nbsp;ПФХД&nbsp;за&nbsp;год", при отсутствии используется "Начальная&nbsp;цена", или если
              договор расторгнут используется "Сумма&nbsp;по&nbsp;платежам"
            </p>
            <p>
              <b>Объем контрактации (% от СГОЗ)</b> - соотношение "Сумма&nbsp;начатых&nbsp;процедур" к "СГОЗ"
            </p>
            <p>
              <b>Сумма экономии</b> - суммируется разница между "Цена&nbsp;договора" и "Начальная&nbsp;цена"
            </p>
            <p>
              <b>Остаток незаконтрактованных средств</b> - свободные лимиты
            </p>
          </div>
        </div>
      </div>
    )
  );
};
